import Layout from "../components/Layout";
import Banner from "../components/Banner";
import React from "react";

export default function Privacy() {
    return (
        <Layout>
            <Banner heightSmall={true} backgroundImage={'/media/mv-01.jpg'}>
                <div className={"banner-title"}>
                    <span style={{fontSize: "3rem", fontWeight: "bold"}}>Privacy</span><br/>
                </div>
            </Banner>
            <article className="section">
                <h1>
                    Privacy Policy of Pacific Technology Corp.
                </h1>

                Pacific Technology Corp. operates the www.pacifictechnologyusa.com website, which provides the SERVICE.
                This page is used to inform website visitors regarding our policies with the collection, use, and
                disclosure of Personal Information if anyone decided to use our Service, the pacifictechnologyusa
                website.
                If you choose to use our Service, then you agree to the collection and use of information in relation
                with this policy. The Personal Information that we collect are used for providing and improving the
                Service. We will not use or share your information with anyone except as described in this Privacy
                Policy.
                <h1>
                    Information Collection and Use
                </h1>
                For a better experience while using our Service, we may require you to provide us with certain
                personally identifiable information, including but not limited to your name, phone number, and postal
                address. The information that we collect will be used to contact or identify you.
                <h1>
                    Log Data
                </h1>
                We want to inform you that whenever you visit our Service, we collect information that your browser
                sends to us that is called Log Data. This Log Data may include information such as your computer’s
                Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and
                date of your visit, the time spent on those pages, and other statistics.
                <h1>
                    Service Providers
                </h1>
                We may employ third-party companies and individuals due to the following reasons:
                • To facilitate our Service;
                • To provide the Service on our behalf;
                • To perform Service-related services; or
                • To assist us in analyzing how our Service is used.
                We want to inform our Service users that these third parties have access to your Personal Information.
                The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to
                disclose or use the information for any other purpose.
                <h1>
                    Security
                </h1>
                We value your trust in providing us your Personal Information, thus we are striving to use commercially
                acceptable means of protecting it. But remember that no method of transmission over the internet, or
                method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                <h1>
                    Links to Other Sites
                </h1>
                Our Service may contain links to other sites. If you click on a third-party link, you will be directed
                to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you
                to review the Privacy Policy of these websites. We have no control over, and assume no responsibility
                for the content, privacy policies, or practices of any third-party sites or services.
                <h1>
                    Children's Privacy
                </h1>
                Our Services do not address anyone under the age of 13. We do not knowingly collect personal
                identifiable information from children under 13. In the case we discover that a child under 13 has
                provided us with personal information, we immediately delete this from our servers. If you are a parent
                or guardian and you are aware that your child has provided us with personal information, please contact
                us so that we will be able to do necessary actions.
                <h1>
                    Changes to This Privacy Policy
                </h1>
                We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically
                for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These
                changes are effective immediately, after they are posted on this page.
                <h1>
                    Contact Us
                </h1>
                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
            </article>

        </Layout>
    )
}
